import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import { Homepage } from "../../frontend/pages/Homepage";
import { HeaderSticky } from "../../frontend/components/header/Header";
import logo from "../../assets/icons/logo.png";
import icons from "bootstrap-italia/dist/svg/sprites.svg";
import AuthUser from "./AuthUser";
import Servizio from "../../frontend/pages/Servizio";
import User from "./User";
import LoginSso from "./LoginSso";
import LogoutPage from "./logoutPage";
import ProfilePage from "./ProfilePage";
import Footer from "../../frontend/components/footer/Footer";
import CreaUfficio from "../../frontend/pages/CreaUfficio";

const Auth = () => {
  const { token, logout } = AuthUser();
  const [isMobile, setIsMobile] = useState(
    sessionStorage.getItem("mobile") || false
  );

  useEffect(() => {
    // Ottieni l'URL corrente
    const url = new URL(window.location.href);

    // Estrai i parametri di query
    const queryParams = new URLSearchParams(url.search);

    // Leggi i parametri di query specifici
    const mobile = queryParams.get("mobile");

    if (mobile === "true") {
      setIsMobile(true);
      sessionStorage.setItem("mobile", true);
    }
  }, []);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const logoutUser = () => {
    if (!token !== undefined) {
      logout();
    }
  };
  return (
    <div>
      {!isMobile && (
        <header className="it-header-wrapper">
          <div className="it-header-slim-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="it-header-slim-wrapper-content ">
                    <a className="d-none d-lg-block navbar-brand left-0" href="https://portale.regione.calabria.it/website/">Regione Calabria</a>

                    {/*    <div className="nav-mobile">
                    <nav aria-label="Navigazione secondaria">
                      <div className="link-list-wrapper collapse" id="menuC1">
                        <ul className="link-list">
                          <li>
                            <a className="dropdown-item list-item">Link 1</a>
                          </li>
                          <li>
                            <a className="list-item active" aria-current="page">
                              Link 2 (Attivo)
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div> */}
                    <div className="it-header-slim-right-zone flex justify-content-end">
                      <div className="it-access-top-wrapper">
                        <span
                          onClick={logoutUser}
                          className="btn btn-primary  btn-sm"
                        >
                          Logout
                        </span>
                      </div>
                      <div>
                        <Link
                            to={"/profile"}
                            className="btn btn-primary btn-sm  d-flex align-items-center"
                        >
                          <span
                              className="bg-white rounded-circle icon-wrapper mr-2"
                              aria-hidden="true"
                          >
                            <svg className="icon icon-primary">
                              <use href={`${icons}#it-user`}></use>
                            </svg>
                          </span>
                          <span>{`${user.firstname} ${user.lastname}`}</span>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="it-nav-wrapper">
            <div className="it-header-center-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="it-header-center-content-wrapper">
                      <div className="it-brand-wrapper">
                        <Link className="dropdown-item list-item" to={"/"}>
                          <img
                              className={"mr-20 icon"}
                              style={{ width: '70px'}}
                              aria-hidden={"true"}
                              src={logo}
                              alt={"logo"}
                          />
                          <div className="it-brand-text">
                            <div className="it-brand-title">
                              Comune di Lago
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="it-right-zone">
                        <div className="it-socials d-none d-md-flex">
                          <span>Seguici su</span>
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/comuneditreviso/?locale=it_IT"
                                aria-label="Facebook"
                                target="_blank"
                              >
                                <svg className="icon">
                                  <use href={`${icons}#it-facebook`}></use>
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.instagram.com/comune_treviso"
                                aria-label="Instagram"
                                target="_blank"
                              >
                                <svg className="icon">
                                  <use href={`${icons}#it-instagram`}></use>
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/ComuneTreviso"
                                aria-label="Twitter"
                                target="_blank"
                              >
                                <svg className="icon">
                                  <use href={`${icons}#it-twitter`}></use>
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/channel/UCOumWkFRA4HqyR6HRa_uY_g"
                                aria-label="Youtube"
                                target="_blank"
                              >
                                <svg className="icon">
                                  <use href={`${icons}#it-youtube`}></use>
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="it-header-navbar-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <nav className="navbar navbar-expand-lg has-megamenu" aria-label="Navigazione principale">
                      <button className="custom-navbar-toggler" type="button" aria-controls="navC1" aria-expanded="false" aria-label="Mostra/Nascondi la navigazione" data-bs-toggle="navbarcollapsible" data-bs-target="#navC1">
                        <svg className="icon">
                          <use href="./assets/bootstrap-italia/svg/sprites.svg#it-burger"></use>
                        </svg>
                      </button>
                      <div className="navbar-collapsable" id="navC1" >
                        <div className="overlay"></div>
                        <div className="close-div">
                          <button className="btn close-menu" type="button">
                            <span className="visually-hidden">Nascondi la navigazione</span>
                            <svg className="icon">
                              <use href="./assets/bootstrap-italia/svg/sprites.svg#it-close-big"></use>
                            </svg>
                          </button>
                        </div>
                        <div className="menu-wrapper">
                          <ul className="navbar-nav">
                            <li className="nav-item"><a className="nav-link" href="https://dev.comunelago10.bbsitalia.com/amministrazione" aria-disabled="true" data-focus-mouse="false"><span>Amministrazione</span></a></li>
                            <li className="nav-item"><a className="nav-link" href="https://dev.comunelago10.bbsitalia.com/novita" aria-disabled="true"><span>Novit</span></a></li>
                            <li className="nav-item"><a className="nav-link" href="https://dev.comunelago10.bbsitalia.com/servizi" aria-disabled="true"><span>Servizi</span></a></li>
                            <li className="nav-item"><a className="nav-link" href="https://dev.comunelago10.bbsitalia.com/vivere-il-comune" aria-disabled="true"><span>Vivere il Comune</span></a></li>

                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/servizio" element={<Servizio />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user" element={<User />} />
        <Route path="/sso" element={<LoginSso />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/crea-ufficio" element={<CreaUfficio />} />
      </Routes>

      {!isMobile && <Footer />}
    </div>
  );
};
export default Auth;
