import { Link } from "react-router-dom";
import prenotaApputamento from "../../assets/icons/aggiungi_appuntamento.svg";
import gestioneAppuntamento from "../../assets/icons/gestisci_appuntamento.svg";
import Login from "../../assets/icons/login.svg";
import Logout from "../../assets/icons/logout.svg";
import Profile from "../../assets/icons/profilo.svg";
import sfondoHomepage from "../../assets/images/sfondo_homepage.png";
import arrowRightOrange from "../../assets/icons/arrowRightOrange.svg";
import { useEffect, useState } from "react";

export const Homepage = () => {
  const token = sessionStorage.getItem("user_jwt");
  const basicToken = sessionStorage.getItem("basic_token");

  const [isMobile, setIsMobile] = useState(
    sessionStorage.getItem("mobile") || false
  );

  useEffect(() => {
    // Ottieni l'URL corrente
    const url = new URL(window.location.href);

    // Estrai i parametri di query
    const queryParams = new URLSearchParams(url.search);

    // Leggi i parametri di query specifici
    const mobile = queryParams.get("mobile");

    if (mobile === "true") {
      setIsMobile(true);
      sessionStorage.setItem("mobile", true);
    }
  }, []);

  const logout = () => {
    const bearerTokenPresent = sessionStorage.getItem("user_jwt");
    sessionStorage.clear();
    if (!!bearerTokenPresent) {
      window.location.href =
        "https://dev.apiprenotazioni.lago.bbsitalia.com/user/logout?bbs_custom_redirect=https://frontend.agendatreviso.bbsitalia.com?mobile=true";
    } else {
      window.location.href = "/";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <img
        src={sfondoHomepage}
        alt={"sfondo homepage"}
        className={"md:w-[80%] md:block hidden absolute -z-10"}
      />
      <div className={!!isMobile ? "mt-4 mb-5 px-4" : "container mt-5 mb-5"}>
        {/*  {!isMobile && (
          <h3 className={"text-lagoBlue font-semibold mb-4"}>
            Prenotazione online degli appuntamenti con gli Uffici comunali
          </h3>
        )} */}
        <div className={"row"}>
          {/*<div className={"col-12 col-md-6 mb-5 px-4"}>*/}
          {/*  /!*<p*!/*/}
          {/*  /!*  style={{*!/*/}
          {/*  /!*    fontSize: "24px",*!/*/}
          {/*  /!*  }}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  Seleziona{" "}*!/*/}
          {/*  /!*  <Link*!/*/}
          {/*  /!*    className="text-decoration-none hover:text-lagoBlue"*!/*/}
          {/*  /!*    to={!!token || !!basicToken ? "/servizio" : "/login-page"}*!/*/}
          {/*  /!*  >*!/*/}
          {/*  /!*    <b*!/*/}
          {/*  /!*      style={{*!/*/}
          {/*  /!*        textDecoration: "underline",*!/*/}
          {/*  /!*      }}*!/*/}
          {/*  /!*    >*!/*/}
          {/*  /!*      PRENOTA APPUNTAMENTO*!/*/}
          {/*  /!*    </b>{" "}*!/*/}
          {/*  /!*  </Link>*!/*/}
          {/*  /!*  per i seguenti servizi*!/*/}
          {/*  /!*</p>*!/*/}
          {/*  /!*<ol className={"pl-0"}>*!/*/}
          {/*  /!*  <li className="table-row">*!/*/}
          {/*  /!*    <div*!/*/}
          {/*  /!*      style={{*!/*/}
          {/*  /!*        flex: 1,*!/*/}
          {/*  /!*      }}*!/*/}
          {/*  /!*    >*!/*/}
          {/*  /!*      ANAGRAFE*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*    <div*!/*/}
          {/*  /!*      className="table-row-right"*!/*/}
          {/*  /!*      style={{*!/*/}
          {/*  /!*        flex: 1,*!/*/}
          {/*  /!*      }}*!/*/}
          {/*  /!*    >*!/*/}
          {/*  /!*      <span*!/*/}
          {/*  /!*        style={{*!/*/}
          {/*  /!*          textAlign: "right",*!/*/}
          {/*  /!*        }}*!/*/}
          {/*  /!*      >*!/*/}
          {/*  /!*        Emissione nuova Carta identità elettronica (CIE)*!/*/}
          {/*  /!*      </span>*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*  </li>*!/*/}
          {/*  /!*  <li className="table-row">*!/*/}
          {/*  /!*    <div*!/*/}
          {/*  /!*      style={{*!/*/}
          {/*  /!*        flex: 1,*!/*/}
          {/*  /!*      }}*!/*/}
          {/*  /!*    >*!/*/}
          {/*  /!*      STATO CIVILE*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*    <div*!/*/}
          {/*  /!*      className="table-row-right"*!/*/}
          {/*  /!*      style={{*!/*/}
          {/*  /!*        flex: 1,*!/*/}
          {/*  /!*      }}*!/*/}
          {/*  /!*    >*!/*/}
          {/*  /!*      <span*!/*/}
          {/*  /!*        style={{*!/*/}
          {/*  /!*          textAlign: "right",*!/*/}
          {/*  /!*        }}*!/*/}
          {/*  /!*      >*!/*/}
          {/*  /!*        Riconoscimento della Cittadinanza (Iure sanguinis)*!/*/}
          {/*  /!*      </span>*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*  </li>*!/*/}
          {/*  /!*  <li className="table-row">*!/*/}
          {/*  /!*    <div>*!/*/}
          {/*  /!*      <div>TRIBUTI</div>*!/*/}
          {/*  /!*      <div>prenotazione telefonica</div>*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*    <div className="table-row-right">*!/*/}
          {/*  /!*      <div>*!/*/}
          {/*  /!*        <span>Ufficio TRIBUTI IMU</span>*!/*/}
          {/*  /!*      </div>*!/*/}
          {/*  /!*      <div>*!/*/}
          {/*  /!*        <span>Ufficio TRIBUTI IDS</span>*!/*/}
          {/*  /!*      </div>*!/*/}
          {/*  /!*      <div>*!/*/}
          {/*  /!*        <span>Ufficio TRIBUTI CUP</span>*!/*/}
          {/*  /!*      </div>*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*  </li>*!/*/}
          {/*  /!*  <li className="table-row">*!/*/}
          {/*  /!*    <div>POLIZIA LOCALE</div>*!/*/}
          {/*  /!*    <div className="table-row-right">*!/*/}
          {/*  /!*      <div>*!/*/}
          {/*  /!*        <div>Ufficio PERMESSI</div>*!/*/}
          {/*  /!*      </div>*!/*/}
          {/*  /!*      <div>*!/*/}
          {/*  /!*        <div>Ufficio OGGETTI RINVENUTI</div>*!/*/}
          {/*  /!*      </div>*!/*/}
          {/*  /!*    </div>*!/*/}
          {/*  /!*  </li>*!/*/}
          {/*  /!*</ol>*!/*/}
          {/*  /!*<p*!/*/}
          {/*  /!*  style={{*!/*/}
          {/*  /!*    fontSize: "24px",*!/*/}
          {/*  /!*  }}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  Seleziona{" "}*!/*/}
          {/*  /!*  <Link*!/*/}
          {/*  /!*    className="text-decoration-none hover:text-lagoBlue"*!/*/}
          {/*  /!*    to={!!token || !!basicToken ? "/user" : "/login-page"}*!/*/}
          {/*  /!*  >*!/*/}
          {/*  /!*    <b*!/*/}
          {/*  /!*      style={{*!/*/}
          {/*  /!*        textDecoration: "underline",*!/*/}
          {/*  /!*      }}*!/*/}
          {/*  /!*    >*!/*/}
          {/*  /!*      GESTIONE APPUNTAMENTI*!/*/}
          {/*  /!*    </b>{" "}*!/*/}
          {/*  /!*  </Link>*!/*/}
          {/*  /!*  per gestire le prenotazioni fissate*!/*/}
          {/*  /!*</p>*!/*/}
          {/*</div>*/}
          <div className={"col-12 col-md-12 row"}>
            <div className="col-12 col-lg-6 mb-3">
              <div className={!isMobile && "card-wrapper card-space"}>
                <div className={!isMobile && "card card-bg"}>
                  <div className="card-body flex items-center">
                    <div className={"left-content mr-4 "}>
                      <div className="left-icon">
                        <img
                          src={`${prenotaApputamento}`}
                          alt={"prenota un appuntamento"}
                        />
                      </div>
                    </div>
                    <div className={"right-content"}>
                      <Link
                        className="text-decoration-none hover:text-lagoBlue"
                        to={
                          !!token || !!basicToken ? "/servizio" : "/login-page"
                        }
                      >
                        <h3 className="text-2xl mb-0 text-lagoBlue font-semibold">
                          Prenota appuntamento
                        </h3>
                      </Link>
                      {!isMobile && (
                        <>
                          <p
                            className="text-[15px]"
                            style={{
                              paddingBottom: "0px",
                              paddingTop: "0px",
                              marginBottom: "0px",
                              marginTop: "0px",
                            }}
                          >
                            La prenotazione può essere effettuata, 24 ore su 24,
                          </p>
                          <p
                            className="text-[15px]"
                            style={{
                              paddingBottom: "0px",
                              paddingTop: "0px",
                              marginBottom: "0px",
                              marginTop: "0px",
                            }}
                          >
                            permette di scegliere il servizio il giorno e
                            all’ora desiderati.
                          </p>
                        </>
                      )}
                    </div>
                    {!isMobile && (
                      <Link
                        className="read-more"
                        to={
                          !!token || !!basicToken ? "/servizio" : "/login-page"
                        }
                      >
                        <span className="text font-normal text-sm text-lagoBlue">
                          Vai alla pagina
                        </span>
                        <img
                          aria-label={"hidden"}
                          className={"icon"}
                          src={arrowRightOrange}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <div className={!isMobile && "card-wrapper card-space"}>
                <div className={!isMobile && "card card-bg"}>
                  <div
                    className={
                      !!isMobile
                        ? "card-body flex items-center"
                        : "card-body flex flex-wrap items-center"
                    }
                  >
                    <div className={"left-content mr-4"}>
                      <div className="left-icon">
                        <img
                          src={`${gestioneAppuntamento}`}
                          alt={"prenota un appuntamento"}
                        />
                      </div>
                    </div>
                    <div className={"right-content"}>
                      <Link
                        className="text-decoration-none"
                        to={!!token || !!basicToken ? "/user" : "/login-page"}
                      >
                        <h3 className="text-2xl mb-0 text-lagoBlue font-semibold text-decoration-none">
                          Gestione appuntamenti
                        </h3>
                      </Link>

                      {!isMobile && (
                        <p className="text-[15px]">
                          Gestione delle prenotazioni fissate
                        </p>
                      )}
                    </div>
                    {!isMobile && (
                      <Link
                        className="read-more"
                        to={!!token || !!basicToken ? "/user" : "/login-page"}
                      >
                        <span className="text font-normal text-sm text-lagoBlue">
                          Vai alla pagina
                        </span>
                        <img
                          aria-label={"hidden"}
                          className={"icon"}
                          src={arrowRightOrange}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!!isMobile && !basicToken && !token && (
              <div className="col-12 col-lg-6">
                <div>
                  <div>
                    <div className={"card-body flex items-center"}>
                      <div className={"left-content mr-4"}>
                        <div className="left-icon">
                          <img
                            src={`${Login}`}
                            alt={"prenota un appuntamento"}
                          />
                        </div>
                      </div>
                      <div className={"right-content"}>
                        <Link
                          className="text-decoration-none"
                          to={"/login-page"}
                        >
                          <h3 className="text-2xl mb-0 text-lagoBlue font-semibold text-decoration-none">
                            Accedi
                          </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!!isMobile && (!!basicToken || !!token) && (
              <>
                <div className="col-12 col-lg-6 mb-3">
                  <div>
                    <div>
                      <div className={"card-body flex items-center"}>
                        <div className={"left-content mr-4"}>
                          <div className="left-icon">
                            <img
                              src={`${Profile}`}
                              alt={"prenota un appuntamento"}
                            />
                          </div>
                        </div>
                        <div className={"right-content"}>
                          <Link
                            className="text-decoration-none"
                            to={"/profile"}
                          >
                            <h3 className="text-2xl mb-0 text-lagoBlue font-semibold text-decoration-none">
                              Profilo
                            </h3>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div>
                    <div>
                      <div className={"card-body flex items-center"}>
                        <div className={"left-content mr-4"}>
                          <div className="left-icon">
                            <img
                              src={`${Logout}`}
                              alt={"prenota un appuntamento"}
                            />
                          </div>
                        </div>
                        <div className={"right-content"}>
                          <div
                            onClick={() => {
                              logout();
                            }}
                            className="text-decoration-none"
                          >
                            <h3 className="text-2xl mb-0 text-lagoBlue font-semibold text-decoration-none">
                              Logout
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
