import React from "react";
import logo from "../../../assets/icons/logo.png";
import icons from "bootstrap-italia/dist/svg/sprites.svg";
import { BASE_URL } from "../../../server"
const Footer = () => {
  return (
    <>
      {/*<footer className="it-footer-custom">*/}
      {/*  <div*/}
      {/*    className="container"*/}
      {/*    style={{*/}
      {/*      margin: "0 auto",*/}
      {/*      maxWidth: "1440px",*/}
      {/*      padding: "0 30px",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div className="it-footer-main-custom">*/}
      {/*      <div className="custom-col">*/}
      {/*        <div*/}
      {/*          style={{*/}
      {/*            display: "flex",*/}
      {/*            flexDirection: "row",*/}
      {/*            alignItems: "center",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <img*/}
      {/*              className={"mr-4"}*/}
      {/*              style={{ width: '70px'}}*/}
      {/*              aria-hidden={"true"}*/}
      {/*              src={logo}*/}
      {/*              alt={"logo"}*/}
      {/*          />*/}
      {/*          <h4 className="no_toc">Comune di Lago</h4>*/}
      {/*        </div>*/}
      {/*        <p className="no-padding no-margin">*/}
      {/*          Via Del Municipio, 16 - 31100 Treviso*/}
      {/*        </p>*/}
      {/*        <p className="no-padding no-margin">TEL: 0422 6581 658201</p>*/}
      {/*        <p className="no-padding no-margin">FAX: 0422 658201</p>*/}
      {/*      </div>*/}
      {/*      <div className="custom-col">*/}
      {/*        <ul>*/}
      {/*          <li>*/}
      {/*            <p className="no-padding no-margin">*/}
      {/*              Ufficio Relazioni Pubblico:{" "}*/}
      {/*              <a href="mailto:urp@comune.treviso.it">*/}
      {/*                urp@comune.treviso.it*/}
      {/*              </a>{" "}*/}
      {/*            </p>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <p className="no-padding no-margin">*/}
      {/*              Protocollo:{" "}*/}
      {/*              <a href="mailto:protocollo@comune.treviso.it">*/}
      {/*                protocollo@comune.treviso.it*/}
      {/*              </a>{" "}*/}
      {/*            </p>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <p className="no-padding no-margin">*/}
      {/*              PEC:{" "}*/}
      {/*              <a href="mailto:postacertificata@cert.comune.treviso.it">*/}
      {/*                postacertificata@cert.comune.treviso.it*/}
      {/*              </a>*/}
      {/*            </p>*/}
      {/*          </li>*/}
      {/*        </ul>*/}
      {/*      </div>*/}
      {/*      <div className="custom-col"></div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</footer>*/}
      {/*<footer className="it-sub-footer-custom">*/}
      {/*  <div*/}
      {/*    className="container"*/}
      {/*    style={{*/}
      {/*      margin: "0 auto",*/}
      {/*      maxWidth: "1440px",*/}
      {/*      padding: "0 30px",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div*/}
      {/*      className="it-footer-main-custom"*/}
      {/*      style={{*/}
      {/*        borderTop: "1px solid #fff",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <ul*/}
      {/*        style={{*/}
      {/*          display: "flex",*/}
      {/*          flexDirection: "row",*/}
      {/*          alignItems: "center",*/}
      {/*          justifyContent: "space-between",*/}
      {/*          width: "100%",*/}
      {/*          flexWrap: "wrap",*/}
      {/*          marginLeft: "0 !important",*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <li className="sub-footer-li">*/}
      {/*          <a*/}
      {/*            target="_blank"*/}
      {/*            referrerPolicy="no-referrer"*/}
      {/*            href="https://www.comune.treviso.it/"*/}
      {/*          >*/}
      {/*            Homepage*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*        <li className="sub-footer-li">*/}
      {/*          <a target="_blank" referrerPolicy="no-referrer" href="#">*/}
      {/*            Dichiarazione di accessibilità*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*        <li className="sub-footer-li">*/}
      {/*          <a*/}
      {/*            target="_blank"*/}
      {/*            referrerPolicy="no-referrer"*/}
      {/*            href="https://www.comune.treviso.it/myportal/C_L407/amministrazione-info/informativa-prenotazioni"*/}
      {/*          >*/}
      {/*            Privacy*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*        <li className="sub-footer-li">*/}
      {/*          <a*/}
      {/*            target="_blank"*/}
      {/*            referrerPolicy="no-referrer"*/}
      {/*            href=" https://www.comune.treviso.it/myportal/C_L407/amministrazione-info/informativa-prenotazioni"*/}
      {/*          >*/}
      {/*            Informativa Cookie*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</footer>*/}



      <footer className="it-footer" id="footer">
        <div className="it-footer-main">
          <div className="container">
            <div className="row">
              <div className="col-12 footer-items-wrapper logo-wrapper">
                <div className="ue-logo"><span className="visually-hidden">logo Unione Europea</span></div>
                <div className="it-brand-wrapper">
                  <a href="https://dev.comunelago10.bbsitalia.com/">
                    <img
                        className={"mr-4"}
                        style={{ width: '60px'}}
                        aria-hidden={"true"}
                        src={logo}
                        alt={"logo"}
                    />
                    <div className="it-brand-text">
                        <h2 className="no_toc">Comune di Lago</h2>
                      </div>
                  </a>
                </div>
                <div className="gstc-logo"><span className="visually-hidden">logo GSTC</span></div>
                <div className="vireo-logo"><span className="visually-hidden">logo Vireo</span></div>
              </div>
            </div>
            <div className="row">



              <div className="col-md-3 footer-items-wrapper">
                <h3 className="footer-heading-title h6 uppercase">Amministrazione</h3>

                <ul className="footer-list">
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/organi-di-governo">
                      Organi di governo
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/aree-amministrative">
                      Aree amministrative
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/uffici">
                      Uffici
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/enti-e-fondazioni">
                      Enti e fondazioni
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/politici">
                      Politici
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/personale-amministrativo">
                      Personale amministrativo
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/amministrazione/documenti-e-dati">
                      Documenti e dati
                    </a>

                  </li>
                </ul>
              </div>

              <div className="col-md-6 footer-items-wrapper">
                <h3 className="footer-heading-title h6 uppercase">Categorie di servizio</h3>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="footer-list">
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/anagrafe-e-stato-civile">
                          Anagrafe e stato civile
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/cultura-e-tempo-libero">
                          Cultura e tempo libero
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/vita-lavorativa">
                          Vita lavorativa
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/imprese-e-commercio">
                          Imprese e commercio
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/appalti-pubblici">
                          Appalti pubblici
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/turismo">
                          Turismo
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/mobilita-e-trasporti">
                          Mobilità e trasporti
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/educazione-e-formazione">
                          Educazione e formazione
                        </a>

                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="footer-list">
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/giustizia-e-sicurezza-pubblica">
                          Giustizia e sicurezza pubblica
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/tributi-finanze-e-contravvenzioni">
                          Tributi, finanze e contravvenzioni
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/ambiente">
                          Ambiente
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/salute-benessere-e-assistenza">
                          Salute, benessere e assistenza
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/autorizzazioni">
                          Autorizzazioni
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/categorie-dei-servizi/agricoltura-e-pesca">
                          Agricoltura e pesca
                        </a>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-3 footer-items-wrapper">
                <h3 className="footer-heading-title h6 uppercase">Novità</h3>

                <ul className="footer-list">
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/tipi-di-notizia/notizie">
                      Notizie
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/tipi-di-notizia/comunicati">
                      Comunicati
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/tipi-di-notizia/avvisi">
                      Avvisi
                    </a>

                  </li>
                </ul>
                <h3 className="footer-heading-title h6 uppercase">Vivere il comune</h3>

                <ul className="footer-list">
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/luoghi">
                      Luoghi
                    </a>

                  </li>
                  <li>
                    <a href="https://dev.comunelago10.bbsitalia.com/eventi">
                      Eventi
                    </a>

                  </li>
                </ul>
              </div>



              <div className="col-md-9 mt-md-4 footer-items-wrapper">
                <h3 className="footer-heading-title h6 uppercase">Contatti</h3>
                <div className="row">
                  <div className="col-md-4">
                    <div className="footer-info">
                      <p><strong>Comune di Lago</strong><br />
                        Piazza Matteotti, n. 4 87035 Lago (CS)<br />
                        protocollo@pec.comune.lago.cs.it<br />
                        Tel. +39.0982 454071<br />
                        Fax +39.0982 454172<br />
                        C.F.8600083078 – P.Iva 01385940786<br />
                        <br />
                        Segretario Comunale: Avv. Alfio Bonaventura<br />
                        Email: segretario@comune.lago.cs.it</p>


                    </div>
                  </div>
                  <div className="col-md-4">
                    <ul className="footer-list">
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/domande-frequenti" data-element="faq">Leggi le FAQ
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/prenotazione-appuntamento" data-element="">Prenotazione appuntamento
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/segnalazioni_page" data-element="report-inefficiency">Segnalazione disservizio
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/richiedi-assistenza" data-element="">Richiesta d'assistenza
                        </a>

                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="footer-list">
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/amministrazione-trasparente" data-element="">Amministrazione trasparente
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/privacy" data-element="privacy-policy-link">Informativa privacy
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/note-legali" data-element="legal-notes">Note legali
                        </a>

                      </li>
                      <li>
                        <a href="https://dev.comunelago10.bbsitalia.com/" data-element="accessibility-link">Dichiarazione di accessibilità
                        </a>

                      </li>
                      <li>
                        <a href="http://archivio.comunelago.bbsitalia.com/amministrazione-trasparente/avcp-pubblicazione" data-element="">AVCP – Pubblicazione dei dati ai sensi dell’art.1 comma 32 Legge n.190/2012
                        </a>

                      </li>
                      <li>
                        <a href="http://archivio.comunelago.bbsitalia.com/albo-online/" data-element="">Albo Pretorio
                        </a>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>



              <div className="col-md-3 mt-md-4 footer-items-wrapper">
                <h3 className="footer-heading-title h6 uppercase">Seguici su</h3>
                <ul>
                  <li>
                    <a
                        href="https://www.facebook.com/comune.lago"
                        aria-label="Facebook"
                        target="_blank"
                    >
                      <svg className="icon">
                        <use href={`${icons}#it-facebook`}></use>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://api.whatsapp.com/send?phone=393387164787&text=REGISTRAMI"
                        aria-label="Whatsapp"
                        target="_blank"
                    >
                      <svg className="icon">
                        <use href={`${icons}#it-whatsapp`}></use>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                        href="https://www.instagram.com/comunedilago/"
                        aria-label="Instagram"
                        target="_blank"
                    >
                      <svg className="icon">
                        <use href={`${icons}#it-instagram`}></use>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12 footer-items-wrapper">

                <div className="footer-bottom">
                  <a href="/">
                    Media policy
                  </a>

                  <a href="/">
                    Mappa del sito
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
