import React, { useState } from 'react';
import axios from 'axios';
import getHeaders from "../../utils/handleHeaderFunc";

function CreaUfficio() {
    const [officesFromComuneLago, setOfficesFromComuneLago] = useState([]);
    const [officesFromAgenda, setOfficesFromAgenda] = useState([]);
    const [summary, setSummary] = useState([]);
    const headers = getHeaders();

    const getOfficesFromComuneLago = async () => {
        try {
            const response = await axios.get('https://dev.comunelago10.bbsitalia.com/tutti-gli-uffici');
            setOfficesFromComuneLago(response.data);
        } catch (error) {
            console.error('Error fetching offices from ComuneLago:', error);
        }
    };

    const getOfficesFromAgenda = async () => {
        try {
            const response = await axios.get('https://dev.apiprenotazioni.lago.bbsitalia.com/offices',{
                headers: headers,
            });
            setOfficesFromAgenda(response.data.offices);
        } catch (error) {
            console.error('Error fetching offices from Agenda:', error);
        }
    };

    const checkAndAddOffices = async () => {
        const summaryArray = [];

        for (const office of officesFromComuneLago) {
            const exists = officesFromAgenda.some((agendaOffice) => agendaOffice.name === office.name);

            if (exists) {
                summaryArray.push('X');
            } else {
                try {
                    await axios.post('https://dev.apiprenotazioni.lago.bbsitalia.com/add_office', {
                        name: office.name
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            // basic auth // base64 encoded string of username:password
                            Authorization: `Basic YWRtaW5AbGFnby5pdDpQYXNzd29yZDEh`,
                        },
                    });
                    summaryArray.push('V');
                } catch (error) {
                    console.error('Error adding office to Agenda:', error);
                    summaryArray.push('E');
                }
            }
        }

        setSummary(summaryArray);
    };

    return (
        <div>
            <button onClick={getOfficesFromComuneLago}>Get Offices from ComuneLago</button>
            <br></br>
            <button onClick={getOfficesFromAgenda}>Get Offices from Agenda</button>
            <br></br>
            <button onClick={checkAndAddOffices}>Check and Add Offices</button>
            <br></br>

            <h2>Summary:</h2>
            <ul>
                {summary.map((status, index) => (
                    <li key={index}>{status}</li>
                ))}
            </ul>
        </div>
    );
}

export default CreaUfficio;
