import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthUser from "./AuthUser";
import { BASE_URL } from "../../server";
import sha256 from "crypto-js/sha256";
import getHeaders from "../../utils/handleHeaderFunc";
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import icons from "bootstrap-italia/dist/svg/sprites.svg";

const ProfilePage = () => {
  const basicAuth = sessionStorage.getItem("basic_token");
  const userJwt = sessionStorage.getItem("user_jwt");
  const profile = JSON.parse(sessionStorage.getItem("user"));
  const isMobile = sessionStorage.getItem("mobile") || false;

  const [formData, setFormData] = useState({
    firstname: profile.firstname,
    lastname: profile.lastname,
    fiscalcode: profile.fiscalcode,
    phoneNumber: profile.phoneNumber,
    email: profile.email,
    pin: profile.pin || null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { firstname, lastname } = formData;
    if (!firstname || !lastname) {
      setError("Modifica nome e cognome.");
      return false;
    }
    return true;
  };

  const navigate = useNavigate();

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    /*     if (!validateForm()) {
      setLoading(false);
      return;
    }
 */

    const headers = getHeaders();

    try {
      const apiUrl = `${BASE_URL}/update_user`;
      const data = {
        profile: {
          id: JSON.parse(sessionStorage.getItem("user")).id,
          ...formData,
          competence_id: 4,
        },
      };
      const response = await axios.put(apiUrl, data, { headers: headers });

      if (response.status === 200) {
        sessionStorage.setItem(
          "user",
          JSON.stringify(Object.assign({}, response.data.profile, formData))
        );
        setShowSuccessAlert(true);
        setTimeout(() => setShowSuccessAlert(false), 3000);
        setIsEdit(false);
      } else {
        setError("Update fallito. Riprova.");
      }
    } catch (error) {
      console.error(error);
      setError("Si è verificato un errore durante l'update.");
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const bearerFields = [
    {
      name: "firstname",
      label: "Nome",
      placeholder: "Aggiungi/modifica il tuo nome",
      value: formData.firstname,
      updatable: false,
    },
    {
      name: "lastname",
      label: "Cognome",
      placeholder: "Aggiungi/modifica il tuo cognome",
      value: formData.lastname,
      updatable: false,
    },
    {
      name: "fiscalcode",
      label: "Codice fiscale",
      placeholder: "Aggiungi/modifica il tuo codice fiscale",
      value: formData.fiscalcode,
      updatable: false,
    },
    {
      name: "phoneNumber",
      label: "Numero di telefono",
      placeholder: "Aggiungi/modifica il tuo numero di telefono",
      value: formData.phoneNumber,
      updatable: true,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Aggiungi/modifica la tua mail",
      value: formData.email,
      updatable: true,
    },
  ];

  const basicFields = [
    {
      name: "firstname",
      label: "Nome",
      placeholder: "Aggiungi/modifica il tuo nome",
      value: formData.firstname,
      updatable: false,
    },
    {
      name: "lastname",
      label: "Cognome",
      placeholder: "Aggiungi/modifica il tuo cognome",
      value: formData.lastname,
      updatable: false,
    },
    {
      name: "pin",
      label: "PIN",
      placeholder: "Aggiungi/modifica il tuo PIN",
      value: formData.pin,
      updatable: false,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Aggiungi/modifica la tua mail",
      value: formData.email,
      updatable: true,
    },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className={!!isMobile ? "padding-mobile" : "shadow p-5 mt-5"}>
          <div className="mb-3" onClick={() => navigate(-1)}>
            <a href="#" className="flex items-center text-lagoVerde">
              {/*<img*/}
              {/*  src={`${arrowLeft}`}*/}
              {/*  className={"icon"}*/}
              {/*  style={{*/}
              {/*    width: "24px",*/}
              {/*    height: "24px",*/}
              {/*    marginRight: "4px",*/}
              {/*  }}*/}
              {/*  alt={"Torna alla pagina precedente"}*/}
              {/*/>*/}
              <svg className="icon icon-sm fill-lagoVerde">
                <use href={`${icons}#it-arrow-left`}></use>
              </svg>
              Indietro
            </a>
          </div>
          <div className="mb-5">
            <h2>Dati Profilo</h2>
            <p>Aggiungi/Modifica i tuoi dati personali</p>
          </div>
          {showSuccessAlert && (
            <div className="alert alert-success mt-4" role="alert">
              Dati aggiornati con successo!
            </div>
          )}

          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={submitForm}>
            {!!basicAuth
              ? basicFields.map((field) => (
                  <FormGroup key={field.name}>
                    <Label for={field.name} className="font-semibold">
                      {field.label}
                    </Label>
                    <Input
                      defaultValue={profile[field.name]}
                      disabled={!!isEdit && !field.updatable}
                      type="text"
                      name={field.name}
                      id={field.name}
                      placeholder={field.placeholder}
                      value={field.value}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                ))
              : !!userJwt &&
                bearerFields.map((field) => (
                  <FormGroup key={field.name}>
                    <Label for={field.name} className="font-semibold">
                      {field.label}
                    </Label>
                    <Input
                      disabled={!isEdit && !field.updatable}
                      type="text"
                      name={field.name}
                      id={field.name}
                      placeholder={field.placeholder}
                      value={field.value}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                ))}

            {!isEdit ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "32px",
                }}
              >
                <div>
                  <Button
                    style={{
                      fontSize: "16px",
                    }}
                    onClick={() => navigate("/")}
                    color="primary"
                  >
                    Torna alla home
                  </Button>
                </div>
                <div
                  className="btn btn-primary btn-sm "
                  style={{
                    fontSize: "16px",
                  }}
                  onClick={() => setIsEdit(true)}
                  color="primary"
                >
                  Modifica
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "justify-between",
                  marginTop: "32px",
                }}
              >
                <div
                  className="btn btn-danger btn-sm mr-2"
                  style={{
                    fontSize: "16px",
                  }}
                  onClick={() => setIsEdit(false)}
                  color="primary"
                >
                  Annulla
                </div>
                {loading ? (
                  <Button type="submit" color="primary" disabled>
                    <span className="spinner-border spinner-border-sm"></span>
                  </Button>
                ) : (
                  <Button
                    style={{
                      fontSize: "16px",
                    }}
                    type="submit"
                    color="primary"
                  >
                    Salva
                  </Button>
                )}
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
