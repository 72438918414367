import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../server";
import icons from "bootstrap-italia/dist/svg/sprites.svg";

function LoginSso() {
  const navigation = useNavigate();
  const queryString = window.location?.search;
  const [userJwt, setUserJwt] = useState(null);
  const [error, setError] = useState(null);
  const isMobile = sessionStorage.getItem("mobile") || false;

  // Utilizzare URLSearchParams per analizzare la stringa della query
  const urlParams = new URLSearchParams(queryString);

  // Ottenere i valori dei parametri dalla stringa della query
  const token = urlParams?.get("token");
  const nome_servizio = urlParams?.get("nome_servizio");
  const field_codice_fiscale = urlParams?.get("field_codice_fiscale");
  const callback_url = urlParams?.get("callback_url");

  useEffect(() => {
    const fetchData = async () => {
      console.log("token", token);
      console.log("nome_servizio", nome_servizio);
      console.log("field_codice_fiscale", field_codice_fiscale);
      if (token && nome_servizio) {
        // axios call to callback_url

        try {
          const response = await axios.post(callback_url, {
            token: token,
            nome_servizio: nome_servizio,
            field_codice_fiscale: field_codice_fiscale || null,
          });

          console.log("response", response);

          if (response.status === 200) {
            sessionStorage.setItem("user_jwt", response.data.user_jwt);
            console.log("user_jwt", response.data.user_jwt);
            setUserJwt(response.data.user_jwt);
            sessionStorage.setItem(
              "user_profile",
              JSON.stringify(response.data.user_profile)
            );
          } else {
            // error
            console.log("response data error", response.data);
            setError("Errore");
          }
        } catch (error) {
          console.error("ERROre", error);
          setError("Errore");
        }
      }
    };

    fetchData();
  }, [token, nome_servizio, field_codice_fiscale]);

  useEffect(() => {
    if (!!userJwt) {
      try {
        const login = async () => {
          const apiUrl = `${BASE_URL}/user/login`;
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userJwt}`,
          };

          const response = await axios.post(
            apiUrl,
            {
              registration: true,
            },
            { headers: headers }
          );

          console.log("response LOGIN", response);

          if (response.status === 200) {
            sessionStorage.setItem("client_id", response.data.client_id);
            sessionStorage.setItem(
              "user",
              JSON.stringify(response.data.profile)
            );

            if (response.data?.esito === 1) {
              // GET USER PROFILE FROM SESSION STORAGE
              const user_profile = JSON.parse(
                sessionStorage.getItem("user_profile")
              );
              const firstname = user_profile?.field_nome;
              const lastname = user_profile?.field_cognome;
              const email = user_profile?.mail;
              const fiscalcode = user_profile?.field_codice_fiscale;
              const id = response?.data?.client_id;

              // UPDATE USER PROFILE
              try {
                const apiUrl = `${BASE_URL}/update_user`;
                const headers = {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userJwt}`,
                };
                const data = {
                  profile: {
                    id,
                    firstname,
                    lastname,
                    email,
                    fiscalcode,
                    competence_id: 4,
                  },
                };
                const response = await axios.put(apiUrl, data, {
                  headers: headers,
                });

                sessionStorage.setItem(
                  "user",
                  JSON.stringify(response.data.profile)
                );
                navigation("/user");
              } catch (error) {
                console.error("errore nell update", error);
                setError("Errore");
              }
            } else {
              navigation("/user");
            }
          } else {
            // error
            console.log("error", response.data);
            setError("Errore");
          }
        };
        login();
      } catch (error) {
        console.error(error);
        setError("Errore");
      }
    }
  }, [userJwt]);

  return (
    <div className="container">
      <div className="row">
        <div className={!!isMobile ? "padding-mobile" : "shadow p-5 mt-5"}>
          {!error ? (
            <h2>Accesso in corso..</h2>
          ) : (
            <>
              <h3>
                <span className="badge-danger">Errore</span>
              </h3>
              <p>Qualcosa è andato storto. Riprova più tardi.</p>
              <div>
                <a href="https://dev.apiprenotazioni.lago.bbsitalia.com/tv_external_service_access/start/agenda">
                  <button className="btn btn-primary">Torna alla login</button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginSso;
