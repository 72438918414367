import React, { useEffect, useState } from "react";
import icons from "bootstrap-italia/dist/svg/sprites.svg";
import { HeaderSticky as BootstrapHeaderSticky } from "bootstrap-italia";
import logo from "../../../assets/icons/logo.png";
import { Link, useNavigate } from "react-router-dom";
export const HeaderSticky = () => {
  useEffect(() => {
    initializeBootstrapComponents();
  }, []);

  function initializeBootstrapComponents() {
    const headerElements = document.querySelectorAll(
      ".it-header-navbar-wrapper"
    );
    headerElements.forEach((element) => {
      new BootstrapHeaderSticky(element);
    });
  }

  return (
    <header className="it-header-wrapper">
      <div className="it-header-slim-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="it-header-slim-wrapper-content">
                <div className="nav-mobile">
                  <nav aria-label="Navigazione secondaria">
                    <div className="link-list-wrapper collapse" id="menuC1">
                      {/*<ul className="link-list">*/}
                      {/*    <li><a className="dropdown-item list-item">Link 1</a></li>*/}
                      {/*    <li><a className="list-item active" aria-current="page">Link 2 (Attivo)</a></li>*/}
                      {/*</ul>*/}
                    </div>
                  </nav>
                </div>
                <div className="it-header-slim-right-zone">
                  <div className="it-access-top-wrapper">
                    <Link to={"/login-page"} className="btn btn-primary btn-sm">
                      Accedi
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="it-nav-wrapper">
        <div className="it-header-center-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-center-content-wrapper">
                  <div className="it-brand-wrapper">
                    <Link className="dropdown-item list-item" to={"/"}>
                      <img
                        className={"mr-20 icon"}
                        style={{ width: '60px', height: 'auto'}}
                        aria-hidden={"true"}
                        src={logo}
                        alt={"logo"}
                    />
                      <div className="it-brand-text">
                        <div className="it-brand-title">Comune di Lago</div>
                      </div>
                    </Link>
                  </div>
                  <div className="it-right-zone">
                    <div className="it-socials d-none d-md-flex">
                      <span>Seguici su</span>
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/comune.lago"
                            aria-label="Facebook"
                            target="_blank"
                          >
                            <svg className="icon">
                              <use href={`${icons}#it-facebook`}></use>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                              href="https://api.whatsapp.com/send?phone=393387164787&text=REGISTRAMI"
                              aria-label="Whatsapp"
                              target="_blank"
                          >
                            <svg className="icon">
                              <use href={`${icons}#it-whatsapp`}></use>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/comunedilago/"
                            aria-label="Instagram"
                            target="_blank"
                          >
                            <svg className="icon">
                              <use href={`${icons}#it-instagram`}></use>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
