import { Link, useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import React, { useEffect, useRef, useState } from "react";
import { Popup } from "../../frontend/components/popup/Popup";
import icons from "bootstrap-italia/dist/svg/sprites.svg";

const LoginPage = () => {
  const navigation = useNavigate();
  const checkboxRef = useRef(null);
  const isMobile = sessionStorage.getItem("mobile") || false;
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // SCROLL TO TOP OF THE PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToCheckbox = () => {
    // Scrolla fino alla checkbox -60PXS
    checkboxRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  return (
    <>
      {!!showPopup && (
        <Popup
          handleClose={() => {
            setShowPopup(false);
            scrollToCheckbox(); // Chiama la funzione quando il popup viene chiuso
          }}
        >
          <div className="flex flex-col items-center">
            <p>
              Per accedere al servizio è necessario accettare l'informativa
              sulla privacy
            </p>

            <button
              onClick={() => {
                setShowPopup(false);
                scrollToCheckbox(); // Chiama la funzione quando il popup viene chiuso
              }}
              className="btn btn-primary btn-sm"
            >
              Chiudi
            </button>
          </div>
        </Popup>
      )}
      <div className="container">
        <div className="row">
          <div
            className={
              !!isMobile ? "padding-mobile" : "shadow p-5 mt-5 mb-5 pb-0"
            }
          >
            <div className="mb-3" onClick={() => navigation(-1)}>
              <a href="#" className="flex items-center text-lagoVerde">
                {/*<img*/}
                {/*  src={`${arrowLeft}`}*/}
                {/*  className={"icon"}*/}
                {/*  style={{*/}
                {/*    width: "24px",*/}
                {/*    height: "24px",*/}
                {/*    marginRight: "4px",*/}
                {/*  }}*/}
                {/*  alt={"Torna alla pagina precedente"}*/}
                {/*/>*/}
                <svg className="icon icon-sm fill-lagoVerde">
                  <use href={`${icons}#it-arrow-left`}></use>
                </svg>
                Indietro
              </a>
            </div>
            <h4>
              <span className="badge-danger">
                ACCEDI AL SERVIZIO PRENOTAZIONI
              </span>
            </h4>
            {/*<div*/}
            {/*  style={{*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "row",*/}
            {/*    justifyContent: "space-between",*/}
            {/*    marginTop: "36px",*/}
            {/*    flexWrap: "wrap",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {!!isPrivacyChecked ? (*/}
            {/*    <Link*/}
            {/*      style={{*/}
            {/*        fontSize: "20px",*/}
            {/*      }}*/}
            {/*      to={*/}
            {/*        "https://dev.apiprenotazioni.lago.bbsitalia.com/tv_external_service_access/start/agenda"*/}
            {/*      }*/}
            {/*      className="btn btn-primary btn-sm  btn-mobile"*/}
            {/*    >*/}
            {/*      ACCEDI con SPID/CIE*/}
            {/*    </Link>*/}
            {/*  ) : (*/}
            {/*    <div*/}
            {/*      onClick={() => setShowPopup(true)}*/}
            {/*      style={{*/}
            {/*        fontSize: "20px",*/}
            {/*      }}*/}
            {/*      className="btn btn-primary btn-sm  btn-mobile"*/}
            {/*    >*/}
            {/*      ACCEDI con SPID/CIE*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
            <p
                style={{
                  marginTop: "36px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
            >
              Registrati scegliendo un PIN e poi accedi con
              il PIN scelto
            </p>
            {/*<p*/}
            {/*    style={{*/}
            {/*      marginTop: "36px",*/}
            {/*      marginBottom: "0px",*/}
            {/*      padding: "0px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Se non hai SPID/CIE registrati scegliendo un PIN e poi accedi con*/}
            {/*  il PIN scelto*/}
            {/*</p>*/}
            <p>
              <i>
                Avere cura di ricordarsi il PIN per gestire le prenotazioni
                fissate
              </i>
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {!!isPrivacyChecked ? (
                <Link
                  style={{
                    fontSize: "20px",
                    marginRight: "16px",
                    backgroundColor: "#056726",
                  }}
                  to={"/login"}
                  state={{ registrazione: true }}
                  className="btn btn-primary btn-sm btn-mobile"
                >
                  REGISTRATI scegliendo il PIN
                </Link>
              ) : (
                <div
                  onClick={() => setShowPopup(true)}
                  style={{
                    fontSize: "20px",
                    marginRight: "16px",
                    backgroundColor: "#056726",
                  }}
                  className="btn btn-primary btn-sm btn-mobile"
                >
                  REGISTRATI scegliendo il PIN
                </div>
              )}
              {!!isPrivacyChecked ? (
                <Link
                  style={{
                    fontSize: "20px",
                    backgroundColor: "#056726 !important",
                    /*  pointerEvents: isPrivacyChecked ? "auto" : "none",
                opacity: isPrivacyChecked ? "1" : "0.5", */
                  }}
                  to={"/login"}
                  state={{ registrazione: false }}
                  className="btn btn-primary btn-sm btn-mobile"
                >
                  ACCEDI con il PIN scelto
                </Link>
              ) : (
                <div
                  onClick={() => setShowPopup(true)}
                  style={{
                    fontSize: "20px",
                    backgroundColor: "#056726 !important",
                    /*  pointerEvents: isPrivacyChecked ? "auto" : "none",
                opacity: isPrivacyChecked ? "1" : "0.5", */
                  }}
                  className="btn btn-primary btn-sm btn-mobile"
                >
                  ACCEDI con il PIN scelto
                </div>
              )}
            </div>
            <p className="text-[16px] mb-3 mt-3">
              Informativa sulla Privacy La informiamo che i dati personali
              acquisiti saranno trattati nel rispetto del regolamento UE
              679/2016 e del d.lgs. 196/2003, così come modificato dal
              d.lgs.101/2018, con modalità idonee a garantirne la riservatezza e
              la sicurezza ed esclusivamente al fine di consentire la
              lavorazione della segnalazione inviata. Per l'informativa completa
              cliccare sul seguente link:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.comune.treviso.it/myportal/C_L407/amministrazione-info/informativa-prenotazioni"
              >
                Informativa sul trattamento dei dati personali
              </a>{" "}
            </p>
            <input
              ref={checkboxRef}
              onChange={(e) => setIsPrivacyChecked(e.target.checked)}
              type="checkbox"
              className="mb-5 mr-2"
            />
            Ho letto l'informativa
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
